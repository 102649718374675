$(function(){
    /**
     * Place the CSRF token as a header on all pages for access in AJAX requests
     */
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });

    $(document).ready(function(){

        // Keeping aspect ratio for responsive videos
        $('.ui.embed:not(.autoplay)').embed();
        $('.ui.autoplay.embed').embed({ 'autoplay': true });
        
        // Activating Semantic Datepicker
        $('.datepicker').calendar({
          type: 'date'
        });

        // Activating popups for class
        $('.popup-activator').popup();

        // Main navigation dropdown
        $('.ui.main.dropdown').dropdown({
          action: 'hide',
          on: 'hover'
        });

        // Default dropdown if .unique is not specified
        $('.ui.dropdown:not(.unique)').dropdown();

        // Video card swipers
        var swiperInstances = {};
        $(".video-swiper-container").each(function(index, element){
            var $this = $(this);
            $this.addClass("instance-video" + index);
            $this.parent().find(".swiper-button-prev").addClass("btn-prev-" + index);
            $this.parent().find(".swiper-button-next").addClass("btn-next-" + index);
            swiperInstances['video'+index] = new Swiper(".instance-video" + index, {
                nextButton: ".btn-next-" + index,
                prevButton: ".btn-prev-" + index,

                autoHeight: true,
                slidesPerView: 4,
                spaceBetween: 15.65,
                breakpoints: {
                  1024: {
                      slidesPerView: 4,
                      spaceBetween: 40
                  },
                  768: {
                      slidesPerView: 3,
                      spaceBetween: 30
                  },
                  640: {
                      slidesPerView: 2,
                      spaceBetween: 20
                  },
                  400: {
                      slidesPerView: 1,
                      spaceBetween: 10
                  }
                }
            });
        });

        // Homepage Block Slider 
        homepageBlockSlider = new Swiper(".block-swiper-container", {
          autoHeight: true,
          pagination: '.swiper-pagination',
          paginationClickable: true,
          loop: true,
          autoplay: 5500,
          autoplayDisableOnInteraction: true,
          slidesPerView: 'auto',
        });

        $(".flags-swiper-container").each(function(index, element){
            var $this = $(this);
            $this.addClass("instance-video" + index);
            $this.parent().find(".swiper-button-prev").addClass("btn-prev-" + index);
            $this.parent().find(".swiper-button-next").addClass("btn-next-" + index);
            swiperInstances['video'+index] = new Swiper(".instance-video" + index, {
                nextButton: ".btn-next-" + index,
                prevButton: ".btn-prev-" + index,

                autoHeight: true,
                slidesPerView: 2,
                spaceBetween: 15.65,
                breakpoints: {
                  1024: {
                      slidesPerView: 2,
                      spaceBetween: 40
                  },
                  768: {
                      slidesPerView: 2,
                      spaceBetween: 30
                  },
                  640: {
                      slidesPerView: 1,
                      spaceBetween: 20
                  },
                  400: {
                      slidesPerView: 1,
                      spaceBetween: 10
                  }
                }
            });
        });


        /**
         * Add the data-method="delete" forms to all delete links
         */
        addDeleteForms();

        /**
         * Generic confirm form delete using Sweet Alert
         */
        $('body').on('submit', 'form[name=delete_item]', function(e){
            e.preventDefault();
            var form = this;
            var link = $('a[data-method="delete"]');
            //var cancel = (link.attr('data-trans-button-cancel')) ? link.attr('data-trans-button-cancel') : "Cancel";
            //var confirm = (link.attr('data-trans-button-confirm')) ? link.attr('data-trans-button-confirm') : "Yes, delete";
            //var title = (link.attr('data-trans-title')) ? link.attr('data-trans-title') : "Warning";
            var title = (link.attr('data-trans-title')) ? link.attr('data-trans-title') : "Are you sure you want to delete?";

            $('.ui.delete.modal .header').html(title);
            $('.ui.delete.modal')
              .modal({
                onApprove : function() {
                  form.submit();
                }
              })
              .modal('show')
        });
  
    });

    /**
     * Allows you to add data-method="METHOD to links to automatically inject a form
     * with the method on click
     *
     * Example: <a href="{{route('customers.destroy', $customer->id)}}"
     * data-method="delete" name="delete_item">Delete</a>
     *
     * Injects a form with that's fired on click of the link with a DELETE request.
     * Good because you don't have to dirty your HTML with delete forms everywhere.
     */
    function addDeleteForms() {
        $('[data-method]').append(function () {
            if (! $(this).find('form').length > 0)
                return "\n" +
                    "<form action='" + $(this).attr('href') + "' method='POST' name='delete_item' style='display:none'>\n" +
                    "   <input type='hidden' name='_method' value='" + $(this).attr('data-method') + "'>\n" +
                    "   <input type='hidden' name='_token' value='" + $('meta[name="_token"]').attr('content') + "'>\n" +
                    "</form>\n"
            else
                return "";
        })
            .removeAttr('href')
            .attr('style', 'cursor:pointer;')
            .attr('onclick', '$(this).find("form").submit();');
    }

    $('.mobile.sidebar')
      .sidebar('setting', 'transition', 'overlay')
      .sidebar('attach events', '#toggle-mobile-sidebar')
    ;
    $('.cards.sidebar')
      .sidebar('setting', 'transition', 'overlay')
      .sidebar('attach events', '#toggle-cards-sidebar')
    ;

});
